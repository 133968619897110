.background{
    position: fixed;
    height: calc(100vw * 0.56);
    width: 100vw;
    // background-color: blue;
    opacity: 0.65;
    // display: flex;
    // align-items: center;
    // left:125px
}

// .background-left{
//     width: 50%;
//     height: 100%;
//     background-color: red;
// }
// .background-right{
//     width: 50%;
//     margin-left: 200px;
//     background-color: green;
//     height: 100%;
// }

.main-bg-img{
    display: flex;
    justify-content: center;
    position: absolute;
    z-index: 0;
    left: -3px;
    height: calc(100vw * 0.56);

    width: 100vw;
    video,img{
        // width: 100vw ;
        height: 100%;
    }
}
.app-bg-carousel{
    // height:calc(100vw * 0.203);
    height:calc(100vw * 0.1625);

    // background-color: blue;
    position: absolute;
    // left:22.1vw;
    left:22%;

    bottom:61px;
    overflow: hidden;
    width: 40.4vw;
    // width:609px;

}
@media screen and (max-width:calc(100vh *1.9)) {
    .background{
        height: 100vh;
        width: calc(100vh * 1.77);
        margin: auto;
        // left:0;
        // right: 0;
    }
    .main-bg-img{
        height: 100vh;
        width: calc(100vh * 1.77);
        // img{
        //     width: 100%;
        // }
    }
    .app-bg-carousel{
        height:34.5vh;
        bottom:1.8vh;
        left:21.8%;
        // left:18.8vw;
        width: 72vh;
    }
}
// @media screen and (max-width:1000px) {
//     .app-bg-carousel{
      
//         left:15vw;
//     }
// }