.team-section{
    position: relative;
    z-index: 1;
    text-align: center;
    margin-top: 0rem;
}
.team-section-semi-title{
    margin: 2rem auto 3rem auto;
    width: 1000px;
    max-width: 90vw;
}
.team-section-title-2{
    margin: 0rem 0 0 0;
}

@media screen and (max-width:500px) {
    .team-section{
        margin-top: 8rem;
    }
    .team-section-semi-title{
        width: 90vw;
        margin: 0.6rem auto 2rem auto;
        text-align: center;
    }
}