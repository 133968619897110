.footer {
    position: relative;
    display: flex;
    align-items: center;
    width: 100vw;
    margin: 5rem 0 0;
    height: 160px;
}

.footer-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    width: 1120px;
    max-width: 90vw;
}
@media screen and (max-width:500px) {
    .footer {
        margin: 0rem 0 0;
    }
}