$lime-green: #BDFF00;
$blue:#99f4ff;

.header {
    width: 100vw;
    height: 78px;
    background-color: black;
    position: fixed;
  
    left: 0;
    z-index: 100;
}

.header-inner {
    position: relative;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 1120px;
    max-width: 90vw;
    height: 100%;
}

.header-left {
    height: 100%;
}

.header-desktop-logo-wrapper {
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;

    img {
        height: 67%;
    }
}

.header-middle {
    display: flex;
    justify-content: space-between;
    width: 400px;

    button {
        transition: all 100ms ease-in;

        &:hover {
            // color: $lime-green;
        }
    }
}
#header-desktop-dropdown{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: start;
    top:2px;
    height: 30px;
    // background-color: red;
    // hei
    &:hover{    
        .roadmap-drop-down-desktop{
            display: block;
            opacity: 1;
            top:30px;
            // transition: all 300ms ease-in-out;
        }
      
        // margin-top: 25px;
       
        
    }   
}
// #header-desktop-dropdown:hover + #header-desktop-dropdown-open-btn{
//     height: 50px;
// }
#header-desktop-dropdown-open-btn{
    // 
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 5px;
    // width: 115px;
    height: 25px;
    // background-color: rgba(255,255,255,0.35);
    // background-color: $lime-green;
    color:white;
    
    img{
        height:6px;
        margin-left: 5px;
    }
   
}
.roadmap-drop-down-desktop{
    position: absolute;
    display: none;
    opacity: 0;
    top:40px;
    width: 115px;
    height: 5;
    
    border-radius:0 0 5px 5px ;
    // background-color: rgba(255,255,255,0.35);
    background-color: white;
    box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.18);
    border-radius: 5px;
  
    button{
        width: 105px;
        height: 32px;
       
        padding-left: 10px;
        text-align: start;
        font-weight: bold;
        &:hover{    
            color:black
        }   
    }
}

.header-right {
    display: flex;
    align-items: center;
    height: 100%;
}

.header-twitter-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    height: 35px;
    width: 35px;
    border-radius: 50%;

    img {
        height: 25px;
    }

    transition: all 100ms ease-in;

    &:hover {
        background-color: $lime-green;
    }
}

.header-mint-btn {
    position: relative;
    width: 130px;
    height: 35px;
    background:   $blue;
    color:black;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    span{
        position: relative;
        z-index: 1;
    }
    &:hover{
        opacity: 0.8;
    }
    &::before{
        content:'';
        position: absolute;
        height: 20px;
        width: 95.5%;
        background:  $blue;
        border-top: 2px solid #000000;
        left:0;
        right:0;
        top:-7px;
        margin: auto;
    }
    &::after{
        content:'';
        position: absolute;
        height: 20px;
        width: 95.5%;
        background:   $blue;
        border-bottom: 2px solid #000000;
        left:0;
        right:0;
        bottom:-7px;
        margin: auto;
    }
}

.header-hamburger {
    position: absolute;
    display: none;
    right: 0;
    z-index: 20;
    opacity: 1;
    transition: opacity 320ms ease-in-out;
}

#header-hamburger-close {
    display: none;
    align-items: center;
    justify-content: center;
    margin-top: 0.9rem;
    height: 30px;
    width: 30px;
    background: rgba(255, 255, 255, 0.65);
    border: 1px solid rgba(255, 255, 255, 0.51);
    box-shadow: 0px 4px 33px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    opacity: 0;
    
}

.header-mobile-nav {
    position: fixed;
    display: none;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 15;
    opacity: 0;
    background: black;
    overflow-y: scroll;
    transition: opacity 320ms ease-in-out;
  
}
.header-mobile-nav-top{
    display: flex;
    justify-content:flex-start;
    // background-color: red;
    align-items: center;
    width: 100%;
    margin: auto;
    margin-bottom: 3rem;
}
.header-mobile-nav-inner {
    margin: 20px auto 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 86vw;
}

.header-mobile-logo {
    margin-bottom: 1rem;
    height: 100px;
}

.header-mobile-nav-btn {
    margin: 0 0 1.7rem 0;

}

.header-mobile-wallet-btn {
 
    display: flex;
    align-items: center;
    justify-content: center;
    width: 297px;
    height: 50px;
    background-color: white;
    border: 3px solid #FC60FF;
    border-radius: 25px;
    margin: 1.5rem 0 0 0;
    font-size: 1.2rem;

    img {
        margin-left: 0.6rem;
    }

    color: black;
}

.header-mobile-nft-img {
    position: absolute;
    bottom: 0;
    margin-left: 7vw;
}

.header-mobile-logo-wrapper {
    cursor: pointer;
    height: 120px;
    display: flex;
    align-items: center;
    // margin-left: -12px;

    img {
        position: relative;
        top: 5px;
        height: 67%;
    }
}

.header-mobile-dropdown{
    
    width: 100%;
    margin: 0 0 1rem 0;
    // background-color: red;
    // height:
 
}
.header-mobile-dropdown-btn{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    height: 30px;
    // background-color:blue;
    color:black;
    img{
        height:20px;
        transition: transform 300ms ease-in-out;
    }
}
.header-mobile-dropdown-bottom{
    width: 100%;
    height: 0px;
    overflow: hidden;
    button{
        text-align: start;
        height: 45px;
        width: 100%;
        // border: 1px solid blue;
        padding-left: 15px;
        color:black;
    }
    transition: height 300ms ease-in-out;
}

.header-mobile-twitter-btn{
    height: 40px;
    img{
        height: 100%;
    }
}
#header-hamburger-menu{
    img{
        height: 30px;
    }
}
// @media screen and (max-width:1150px) {
//     .header-middle {
//         width: 62%;
//     }
// }

@media screen and (max-width:990px) {
    .header{
        background-color: white;
        height: 62px;

    }

    .header-left{
        position: absolute;
        width: fit-content;
        margin: auto;
        left: 0;
        right: 0;
        img{
            height: 65px;
        }
        .header-left-text{
            display: none;
        }
    }
    .header-middle {
        display: none;
    }

    .header-right {
        display: none;
    }

    .header-hamburger {
        display: flex;
    }

    .header-mobile-logo {
        display: block;
    }

    .header-mint-btn {
        position: relative;
        width: 90vw;
        height: 40px;
        margin-top: 2rem;
    }

}

@media screen and (max-height:800px) {

    .header-mobile-nav-btn {
        margin: 0 0 1.1rem 0;
    }

    .header-mobile-nft-img {
        display: none;
    }
}

@media screen and (max-width:500px) {
   
    .header-mobile-nft-img {
        margin: auto;
        left: 0;
        right: 0;
    }

    .header-mobile-wallet-btn {
        width: 100%;
        height: 55px;
        border-radius: 35px;
    }

    #header-hamburger-close {
        right:2vw
    }
    
}