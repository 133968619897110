.carousel{
    position: relative;
    width: 1120px;
    height:fit-content;
    box-sizing: content-box;
    // border-radius: 2rem;
    height: 100%;
    // background-color: red;
    // max-width: 90vw;
    overflow: hidden;
}

.carousel-inner{
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
 
}
.carousel-wrapper-1{
    position: relative;
    overflow: hidden;
    width:100%;
    height:100%;
    // background: green;
}
.carousel-track-1{
    position: absolute;
    display: flex;
    height: 100%;
    width: fit-content;
    left:0;
}

.carousel-img{
    height: 100%;
    margin-right: 0.15rem;
    // border-radius: 2rem;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

// @media screen and  (max-width:550px) {
//     .carousel{
//         width: 100vw;
//         max-width: 100vw;
//         margin-left: -5vw;
//         overflow: unset;
//     }
// }
// @media screen and (max-width:500px) {
//     .carousel{
//         margin-top: 5rem;
      
//     }
//     .carousel-img{
//         border-radius: 7px;
//     }
// }