.team-carousel-container {
    position: relative;
    display: flex;
    align-items: center;
    width: 1120px;
    max-width: 85vw;
    margin: auto;
    
}

.team-carousel-inner {
    position: relative;
    width: 100%;
    max-width: 85vw;
    display: flex;
    justify-content: space-between;
    transition: opacity 300ms ease-in;
    // justify-content: space-between;
}
.team-carousel-mobile-container{
    display: none;
    width: 450px;
    margin: auto;
}
.team-carousel-mobile {
   
    position: relative;
    width: 100%;
    // background-color: red;
 
    overflow: hidden;
    padding-bottom: 1rem;
}
.team-carousel-mobile-progress{
    display: flex;
    position: absolute;
    align-items: center;
    width: fit-content;
    margin: -1.5rem auto 0 auto;
    left:0;
    right: 0;
    bottom: -40px;
    div {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        margin: 7px;
        background-color: #D9D9D9;
        cursor: pointer;
    }
}
.team-carousel-mobile-track {

    position: relative;
    left: 0;
    display: flex;
    justify-content: space-between;
    width: fit-content;
    transition: left 300ms ease-in-out;
}

.team-carousel-backward-btn,
.team-carousel-forward-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 30px;
    height: 30px;
    box-shadow: 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 50%;

    img {
        height: 20px;
        margin-top: 4px;
    }
}

.team-carousel-backward-btn {
    left: -60px;
}

.team-carousel-forward-btn {
    right: -60px;
}

@media screen and (max-width:1000px) {
    .team-carousel-container {
        max-width: 90vw;
    }
    
    .team-carousel-inner {
        max-width: 90vw;
    }
    
    .team-carousel-backward-btn,
    .team-carousel-forward-btn {
        display: none;
    }

    .team-carousel-inner {
        display: none;
    }

    .team-carousel-mobile-container{
        display: flex;
    }

    .team-carousel-mobile-track {
        display: flex;
    }

    .team-carousel-mobile {
   
        position: relative;
        width: 90vw;
        // background-color: red;
     
    }
}

