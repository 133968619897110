@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@500&display=swap');
@font-face {
  font-family: 'Egyptian Nights';
  src: url('./assets/fonts/EgyptianNights/EgyptianNights.woff2') format('woff2'),
      url('./assets/fonts/EgyptianNights/EgyptianNights.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'avenir-book';
  src: url(./assets/fonts/Avenir/Book.otf);
}
@font-face {
  font-family: 'avenir-roman';
  src: url(./assets/fonts/Avenir/Roman.otf);
}
@font-face {
  font-family: 'avenir-black';
  src: url(./assets/fonts/Avenir/Black.otf);
}

@font-face {
  font-family: 'vcr';
  src: url(./assets/fonts/vcr.ttf);
}
$blue:#aef7ff;


*{
  margin: 0px;
  padding: 0px;
  -webkit-font-smoothing: antialiased;
}


button{
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.app {
  overflow-x: hidden;
  overflow-y: hidden;
  background-color: black;
 
}



//////\\\\\\
//font-family
//////\\\\\\

.ffam-roboto{
  font-family: 'Roboto',sans-serif;
}
.ffam-quick{
  font-family: 'Quicksand',sans-serif;
}
.ffam-egypt{
  font-family: 'Egyptian Nights',sans-serif;
}
.ffam-poppins{
  font-family: 'Poppins',sans-serif;
}
.ffam-nunito{
  font-family: 'Nunito',sans-serif;
}
.ffam-book{
  font-family: 'avenir-book',sans-serif;
}
.ffam-roman{
  font-family: 'avenir-roman',sans-serif;
}
.ffam-avenir-black{
  font-family: 'avenir-black',sans-serif;
}
.ffam-public{
  font-family: 'Public-Sans',sans-serif;
}
.ffam-vcr{
  font-family: 'vcr',sans-serif;
}

//////\\\\\\
//font-size
//////\\\\\\
.fs-xl {
  font-size: 4.9rem;
}
.fs-xlm {
  font-size: 4.185rem;
  line-height: 100%;
}
.fs-xls {
  font-size: 3.7rem;
  line-height: 100%;
}
.fs-l {
  font-size: 3.1rem;
  line-height: 123%;
}
.fs-ls{
  font-size: 2.4rem;
  line-height: 115%;
}
.fs-m {
  font-size: 2.17rem
}
.fs-ml{
  font-size: 1.43rem
}
.fs-ms {
  font-size: 1.125em;
  line-height: 171%;
}
.fs-s {
  font-size: 1rem;
  line-height: 175%;
}
.fs-header{
  font-size: 0.98rem;
}
.fs-xs {
  font-size:0.96rem;
  line-height: 175%;
}
.fs-xxs {
  font-size: 0.75rem;
}
@media screen and (max-width:1250px) {
  .fs-xlm{
    font-size: 3.6rem;
  }
  .fs-xls {
    font-size: 3.3rem;
  }
  .fs-l{
    font-size: 2.7rem;
  }
  .fs-m {
    font-size: 1.74rem;
  }
}
@media screen and (max-width:1100px) {
  .fs-l{
    font-size: 2.4rem;
  }
  .fs-xls {
    font-size: 3rem;
  }
}


@media screen and (max-width:500px) {

  .fs-xl {
    font-size: 3rem;
  }
  .fs-xlm {
    font-size: 2rem;
  }
  .fs-xls {
    font-size: 2.3rem;
  }
  .fs-l{
    font-size: 1.8rem;
  }
  .fs-ls{
    font-size: 1.8rem;
  }
  .fs-m {
    font-size: 1.25rem;
    line-height: 151%;
  }
  .fs-ml {
    font-size: 1.05rem;
  }
  .fs-ms {
    font-size: 1rem;
    line-height: 151%;
  }
  .fs-s
  {
    font-size: 0.88rem;
  }
  .fs-xs{
    font-size: 0.74rem;
  }



}

//////\\\\\\
//font-color
//////\\\\\\
.fc-white {
  color: #FFFFFF;
  text-shadow: 1px 1px 1px black;
}
.fc-black {
  color: #000;
}
.fc-gray{
  color:#262626;
}
.fc-l-gray{
  color:#282828;
}
.fc-yellow{
  color:  #EBFE74;
  
}
.fc-blue{
  color:$blue;
}
//////\\\\\\
//font-weight
//////\\\\\\
.fw-black {
  font-weight: 900;
}
.fw-extra-bold {
  font-weight: 800;
}
.fw-bold {
  font-weight: 700;
}
.fw-semi-bold {
  font-weight: 600;
}
.fw-med{
  font-weight: 500;
}
.fw-reg {
  font-weight: 400;
}
.fw-light {
  font-weight: 300;
}

.text-underlined{
  text-decoration: underline;
}



//////\\\\\\
//animation-classes
//////\\\\\\

// .pre-animate-top{
//   // position: relative;
//   // top:200px;
//   opacity: 0;
//   transition:top 400ms ease-in-out,opacity 400ms ease-in-out;

// }
.pre-animate-top{
  position: relative;
  top:200px;
  opacity: 0;
  transform: scale3d(0.1,0.1,0.1);
  transition:top 400ms ease-in-out,opacity 400ms ease-in-out, transform 400ms ease-in-out;
}
.show{
  position: relative;
  top:0px;
  opacity: 1;
  transform: scale3d(1,1,1);

}
// .slide-to-top{
//   // top:0px;
//   // left:0px;
//   opacity: 1;
// }
// .indented-section{
//   display: flex;
//   margin-top: 0.4rem;
//   :nth-child(1){
//       margin-right: 5px;
//   }
// }
