$lime-green: #BDFF00;
$blue:#99f4ff;

.about-section {
    position: relative;
    width: 100vw;
    // margin-top: 7rem;
    z-index: 1;
    min-height: 100vh;
    padding-top: 7rem;
}

.about-section-inner {
    // width: 1160px;
    width: 1120px;
    max-width: 90vw;
    margin: auto;

}

.about-section-row-1 {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.about-section-row-1-left {
    position: relative;
    width: 53%;
}

.about-section-row-1-paragraph {
    margin: 1.9rem 0 1.9rem 0;
    text-align: justify;
}

.about-section-carousel-wrapper {
    position: relative;
    width: 100%;
    // background-color: red;
    height: 175px;
}

.about-section-arrow-left,
.about-section-arrow-right {
    position: absolute;
    left: -54px;
    top: 0;
    bottom: 0;
    margin: auto;
    cursor: pointer;
}

.about-section-arrow-right {
    left: unset;
    right: -54px;
}

.about-section-row-1-right {
    display: flex;
    justify-content: center;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    width: 428px;

    img {
        height: 100%;
    }
}

.about-section-title {
    margin-bottom: 0.7rem;
}

.about-section-btn-wrapper-row-1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 599px;
    margin: 2.4rem 0 0 0;

}

.about-section-row-1-right {
    
    width: 400px;

    img {
        width: 100%;
    }
}

.about-section-row-1-btn-1 {
    position: relative;
    width: 23%;
    height: 55.32px;
    background: $blue;

    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    &:hover {
        opacity: 0.8;
    }

    &::before {
        content: '';
        position: absolute;
        height: 20px;
        width: 95.5%;
        background: $blue;
        border-top: 2px solid #000000;
        left: 0;
        right: 0;
        top: -7px;
        margin: auto;
    }

    &::after {
        content: '';
        position: absolute;
        height: 20px;
        width: 95.5%;
        background: $blue;
        border-bottom: 2px solid #000000;
        left: 0;
        right: 0;
        bottom: -7px;
        margin: auto;
    }
}

.about-section-row-1-btn-2 {
    width: 192px;
    height: 55.32px;
    border: 2px solid #01F089FD;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 25px;

    &:hover {
        opacity: 0.8;
    }
}
.about-mobile-progress{
    display: none;
    position: absolute;
    align-items: center;
    width: fit-content;
    margin: -1.5rem auto 0 auto;
    left:0;
    right: 0;
    bottom: 70px;
    div {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        margin: 7px;
        background-color: #D9D9D9;
        cursor: pointer;
    }
}

@media screen and (max-width:1220px) {
    .about-section-btn-wrapper-row-1 {
        width: 100%;
        margin-top: 4rem;
    }

    .about-section-row-1-paragraph {
        text-align: justify;
    }

    .about-section-row-1-left {
        width: 49%;
    }

    .about-section-row-1-right {
        margin: 1.45rem 0 0 0;
    }
}

@media screen and (max-width:1050px) {
    .about-section-row-1-left {
        width: 50%;
    }

    // .about-section-row-1-right{
    //     width: 50%;
    // }
}

@media screen and (max-width:1000px) {
    .about-section-row-1 {
        flex-direction: column-reverse;
        align-items: center;
    }

    .about-section-row-1-left {
        width: 450px;
        text-align: justify;
    }

    .about-section-row-1-right {
        width: 450px;
        margin: 0 0 2rem 0;
    }

    .about-section-title {
        text-align: center;
    }

    .about-section-btn-wrapper-row-1 {
        width: 100%;
        flex-direction: column;
    }

    .about-section-row-1-btn-1 {
       width: 100%;
       margin-bottom: 1.25rem;
    }
}

@media screen and (max-width:600px) {
    .about-section-row-1-left {
        width: 80vw;
    }
}

@media screen and (max-width:500px) {
    .about-section {
        margin-top: 2.5rem;
    }

    .about-section-row-1-left {
        width: 90vw;
    }

    .about-section-arrow-left {
        display: none;
    }

    .about-section-arrow-right {
        display: none;
    }

    .about-section-row-1-right {
        width: 100%;
    }

    .about-section-row-1-btn-1,
    .about-section-row-1-btn-2 {
        width: 47.5%;
        height: 47px;
    }

    .about-section-title {
        margin-bottom: 0rem;
    }

    .about-section-row-1-paragraph {
        margin: 1rem 0 0 0;
    }

    .about-section-btn-wrapper-row-1 {
        margin: 1rem 0 0 0;
        flex-direction: column;
    }

    .health-section-btn-wrapper {
        flex-direction: column;
    }

    .about-section-row-1-btn-1 {
        width: 100%;
    }

    .about-section-row-1-btn-2 {
        width: 100%;
        margin-top: 1rem;
    }

    .about-section {
        padding-top: 4rem;
    }


    .about-section-carousel-wrapper {
        height: 250px;
        // background-color: red;
    }
    .about-mobile-progress{
        display: flex;
    }
}