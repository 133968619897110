.team-card {
    position: relative;
    display: flex;
    // align-items: center;
    flex-direction: column;
    width: 253px;
    height:319px;
    background: #d39bfb;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 0px 2px rgba(145, 158, 171, 0.2);
    padding: 20px;
    box-sizing: border-box;
    flex-shrink: 0;
    border: 2px solid #000000;

    &::before{
        content:'';
        position: absolute;
        height: 20px;
        width: 95.5%;
        background:  #d39bfb;
        border-top: 2px solid #000000;
        left:0;
        right:0;
        top:-7px;
        margin: auto;
    }
    &::after{
        content:'';
        position: absolute;
        height: 20px;
        width: 95.5%;
        background:  #d39bfb;
        border-bottom: 2px solid #000000;
        left:0;
        right:0;
        bottom:-7px;
        margin: auto;
    }

}
.team-card-background{
    position: absolute;
    margin: auto;
    top:0;
    bottom:0;
    left:0;
    right:0;
    height: 98.6%;
    width: 97.6%;
    z-index: 0;
    border-radius: 15px;
    background-color: #d39bfb;
}
.team-card-img {
    position: relative;
    width: 100%;
}



.team-card-desc {
    position: relative;
    text-align: justify;
    font-size:0.6rem;
    margin-top: 0.9rem;
}

.team-card-expand-btn {
    
    position: absolute;
    z-index: 1;
    margin: auto;
    left:0;
    right:0;
    bottom:9px;
    width: 29px;
    height: 29px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    color: black;
}
.team-role-wrapper{
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 20px;
    margin-top: 1rem;
}
.team-card-divider{
    margin: 0 0.3rem 0 0.3rem;
}

@media screen and (max-width:1000px){
    .team-card {
        flex-shrink: 0;
        width: 434px;
        margin: 0 8px 0 8px;
        height: 500px;
        // border-radius: 0px;
        padding:19px 50px 0 50px;
    }
    .team-role-wrapper{
        p{
            font-size: 1.3rem;
        }
        img{
            height: 30px;
            margin-bottom: -10%;
        }
    }
    .team-card-name {
        font-size: 1.3rem;
        margin: 0.8rem 0 0.2rem 0;
    }
    
    .team-card-desc {
       
        font-size: 1.2rem;
    }
    .team-card-background{
        // border-radius: 0px;
    }
}
@media screen and (max-width:500px) {
    .team-card {
       
        width: 86vw;
        margin: 0 2vw 0 2vw;
        height: 550px;
        padding:20px 20px 0 20px;
    }
    .team-card-name {
        position: relative;
        margin: 0.6rem 0 0.5rem 0;
    }

    .team-card-expand-btn {
        bottom:15px;
        width: 50px;
        height: 50px;
        span{
            font-size: 2rem;
        }
    }
    .team-card-desc {
        font-size: 0.9rem;
    }
}

@media screen and (max-width:400px) {
    .team-card {
        height: 450px;
    }

}
