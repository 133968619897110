$lime-green: #BDFF00;
$gray: #C5C5C5;

$blue:#99f4ff;


.roadmap {
    width: 100vw;
    margin-top: 9rem;
    position: relative;

    p {
        text-align: justify;
    }
}

.roadmap-inner {
    width: 1120px;
    max-width: 90vw;

    margin: auto;
}

.roadmap-title {
    text-align: center;
    width: fit-content;
    margin: 0 auto 1rem auto;
    color: $blue;
}

.roadmap-contents-wrapper {
    position: relative;
    width: 100%;
    overflow-x: hidden;
}

.roadmap-contents-wrapper::-webkit-scrollbar {
    display: none;
}

.roadmap-contents {
    display: flex;
    width: 1120px;
    // background-color: red;
}

.roadmap-content {
    cursor: pointer;
    margin: 0 6rem 0 0;
}

.roadmap-phase-name-live,
.roadmap-phase-name {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
    width: 132px;
    background-color: $lime-green;
    margin: 0 0 0.2rem 0;
    padding-top: 2px;
    box-sizing: border-box;
}

.roadmap-phase-name {
    background-color: $gray;
    width: 92px;
}

.roadmap-section-1,
.roadmap-section-2,
.roadmap-section-3 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 4rem 0 0 0;
    // background-color: red;
}
.roadmap-section-1 {
    margin: 0rem 0 0 0;
}

.roadmap-section-3-left {
    width: 43%;
    flex-shrink: 0;


    img {
        width: 100%;
    }
}

.roadmap-section-3-right {
    height: fit-content;
    width: 50%;

    p {
        margin-top: 1rem;
    }
}

.roadmap-section-3-semi-title {
    margin: 0.7rem 0 1.1rem;
}

@media screen and (max-width:1250px) {

    .roadmap-section-3-left {
        margin: 0 2.3rem 0 -14px;
    }

    .roadmap-section-4-left {
        width: 48%;
    }
}

@media screen and (max-width:1000px) {
    // .roadmap-title {
    //     font-size: 5rem;
    //     margin-left: 0px;
    // }

    .roadmap-inner {
        width: 450px;
    }

    .roadmap-phase-name-live {
        width: 112px;
    }

    .roadmap-phase-name {
        width: 80px;
    }

    .roadmap-content {
        margin: 0 4rem 0 0;
    }

    .roadmap-section-btn-wrapper {

        flex-direction: column;
        width: 100%;
    }

    .roadmap-section-section-1-btn-1,
    .roadmap-section-section-1-btn-3 {
        width: 100%;
    }

    .roadmap-section-section-1-btn-2 {
        display: none;
    }

    .roadmap-section-section-1-btn-3 {
        margin-top: 1.5rem;
    }

    .roadmap-section-1,
    .roadmap-section-2,
    .roadmap-section-3 {
        flex-direction: column;
        margin: 5rem 0 0 0;
    }

    .roadmap-section-2 {
        flex-direction: column-reverse;
    }

    .roadmap-section-3-left {

        width: 100%;
        margin: 0 0 -1.5rem 0;
    }

    .roadmap-section-3-right {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
}

@media screen and (max-width:500px) {
    .roadmap-title {
        font-size: 3rem;
    }

    .roadmap-section-1 {
        margin: 3rem 0 0 0;
    }
}