
$green:#fb9bd3;
$blue:#99f4ff;

.faq-section{
    position: relative;
    z-index: 1;
    margin-top: 10rem;
    width: 100vw;
}
.faq-section-inner{
    margin:auto;
    width: 850px;
    max-width: 90vw;
}
.faq-title{
    text-align: center;
    margin-bottom: 3rem;
    width: fit-content;
    margin:0 auto 3rem auto ;
    color:$blue;
}
.faq{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    background: #FFFFFF;
    border: 2px solid #000000;
    box-shadow: 0px 4px 33px rgba(0, 0, 0, 0.1);
    margin-top: 2rem;
    cursor: pointer;
    background: $green;
    span{
        position: relative;
        z-index: 1;
    }

    &::before{
        content:'';
        position: absolute;
        height: 20px;
        width: 98.5%;
        background:  $green;
        border-top: 2px solid #000000;
        left:0;
        right:0;
        top:-7px;
        margin: auto;
    }
    &::after{
        content:'';
        position: absolute;
        height: 20px;
        width: 98.5%;
        // background:  linear-gradient(99.17deg, #EBFE74  0%, rgba(209, 255, 60, 0.993) 70%, rgba(131, 255, 60, 0.993)  100.12%);
        background: $green;

        border-bottom: 2px solid #000000;
        left:0;
        right:0;
        bottom:-7px;
        margin: auto;
    }
}

.faq-question{
    height: 78px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px 0 30px;
    box-sizing: border-box;
    font-size:1.1rem ;
}
.faq-question-no-expand{
    align-items: center;
    height: 130px;
    padding: 0 23px 0 30px;
}

.faq-section-plus{
    transition: transform 200ms ease-in;
    font-size: 1rem;
    font-size: 2rem
}
.faq-answer{
    height: 0px;
    overflow: hidden;
    // background-color: red;
    transition: height 200ms ease-in;
    a{
        color:$green
    }
    text-align: justify;
}
.faq-answer-inner{
    padding: 0 30px 26px 30px;
    height: fit-content;
}

@media screen  and (max-width:725px) {
    .faq-section{
        margin-top:9rem;
        width: 100vw;
    }
    .faq-title{
        margin-bottom: 2rem;
        font-size: 3.5rem;
    }
    .faq-question-title{
        max-width: 95%;
    }
}

@media screen  and (max-width:505px) {
    .faq-section{
        margin-top: 5rem;
    }
    .faq-question{
        
        padding: 0 15px 0 20px;
     
    }
    
    .faq-question-title{
        font-size:0.78rem !important;
    }
    .faq-answer{

        font-size: 0.8rem;
    }
    .faq-answer-inner{
        padding: 0 26px 22px 26px;
    }
    .faq-section-plus{
        
        font-size: 1.5rem !important; 
    }
}
