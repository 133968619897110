.news{
    width: 100vw;
    height: 100vh;
    background-color: black;

    animation:appear 400ms ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
}
@keyframes appear {
    0%{
        opacity: 0;
        transform: scale3d(0.1,1,0.1);
    }
    100%{
        opacity: 1;
        transform: scale(1,1,1);
    }
}
.news-text{
    position: relative;
    flex:1;
    padding-top:15rem;
}
// .main-bg-img{
//     display: flex;
//     justify-content: center;
//     position: fixed;
//     z-index: 0;
//     opacity: 0.45;    
//     width: 100vw;
//     img{
//         // width: 100vw ;
//         height: calc(100vw * 0.56);
//     }
// }
@media screen and (min-height: calc(100vw * 0.5714)) {
    .main-bg-img{
   
        img{
            // width: 100vw ;
            height: 100vh;
        }
    }
}